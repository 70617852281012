<template>
	<ConfirmDialog></ConfirmDialog>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Configuraciones</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Mec&aacute;nicos</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-12 lg:px-12">
		<Toast />
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Mec&aacute;nicos <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge></div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'Medico Crear' in auth.user.permissions"
					v-tooltip.top="'Nuevo Medico'"
					icon="pi pi-plus"
					class="p-button-outlined mr-2 p-button-lg"
					@click="openNuevo"
				/>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtmedicos"
				:value="medicos"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:rowHover="true"
				:filters="buscar"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 50, 100]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Medicos"
				responsiveLayout="scroll"
				showGridlines
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar Medicos..."
							/>
						</span>
					</div>
				</template>

				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
        <Column field="nombre_completo" header="NOMBRE COMPLETO"></Column>
        <Column field="nit" header="NIT"></Column>
        <Column field="especialidad" header="ESPECIALIDAD"></Column>
        <Column field="matricula" header="MATRÍCULA"></Column>
        <Column field="telefono" header="TELÉFONO"></Column>
        <Column field="correo" header="CORREO"></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
				<Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>
		<MedicoCreate
			:show="medicoDialog"
			:medico="medico"
			@closeModal="ocultarDialog"
			@actualizarListado="cargarMedicos"
		>
		</MedicoCreate>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import MedicoService from "@/service/MedicoService";
import { useAuth } from "@/stores";
import MedicoCreate from "@/module/medicos/MedicoCreate.vue";

export default {
	components: {
		MedicoCreate,
	},
	data() {
		return {
			medicos: [],
			cargando: true,
			medicoDialog: false,
			deleteMedicoDialog: false,
			medico: {},
			buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
			enviado: false,
			estados: [
				{ label: "INACTIVO", value: 0 },
				{ label: "ACTIVO", value: 1 },
			],
			errors: {},
			totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	medicoService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.medicoService = new MedicoService();
	},
	mounted() {
		this.cargarMedicos();
	},
	methods: {
		acciones(datos) {
			let permisosUsuarioAuth = this.auth.user.permissions;
			return [
				{
					label: "Editar",
					disabled: "Medico Editar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-pencil",
					command: () => {
						this.editMedico(datos);
					},
				},
				{
					label: "Eliminar",
					disabled: "Medico Eliminar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-trash",
					command: () => {
						this.deleteMedico(datos.id);
					},
				},
			];
		},
    deleteMedico(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea eliminar Medico?",
				header: "Confirmación",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo eliminar",
				rejectLabel: "No, cancelar",
				accept: () => {
					this.medicoService.deleteMedico(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargarMedicos();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
		cargarMedicos() {
			this.medicoService.getMedicosAll().then((data) => {
				this.medicos = data.medicos;
				this.cargando = false;
			});
		},
		openNuevo() {
			this.medico = {
				estado: { label: "Activo", value: 1 },
			};
			this.enviado = false;
			this.medicoDialog = true;
		},
		ocultarDialog() {
			this.medicoDialog = false;
			this.enviado = false;
		},
    editMedico(datos) {
      this.medico = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
      };
      this.medicoDialog = true;

      this.enviado = false;
    },
	},
	watch: {
		medicos() {
			this.totalRegistros = this.medicos.length;
		},
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
